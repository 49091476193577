/*Fonts*/
@import url("https://fonts.googleapis.com/css2?family=Exo:wght@600&display=swap");

html,
body {
  padding: 0;
  margin: 0;
  background: white;
  font-family: "Exo", Arial, sans-serif;
}

#blob,
.btn {
  margin: 0 auto;
  display: block;
}

#blob {
  margin-top: 4vh;
  border: 2px dashed rgb(230, 224, 224);
  padding: 2em;
  width: 35%;
  z-index: 15;
  height: fit-content;
  align-items: center;
  justify-content: center;
}

.btn {
  -webkit-font-smoothing: antialiased;
  text-size-adjust: 100%;
  box-sizing: border-box;
  border-style: solid;
  font-family: inherit;
  margin: 0px;
  overflow: visible;
  text-transform: none;
  background: transparent;
  cursor: pointer;
  padding: 0px;
  color: inherit;
  border-radius: 9999px;
  font-weight: 600;
  width: 4rem;
  display: inline-flex;
  appearance: none;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  transition: all 0.4s ease-in;
  user-select: none;
  position: relative;
  white-space: nowrap;
  vertical-align: middle;
  line-height: 1.2;
  outline: none;
  height: 4rem;
  min-width: 2.5rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: #3f3d56;
  border-color: #fff;
  border-width: 0.125rem;
  box-shadow: none;
  color: #fff;
}

.secondayBtn {
  background-color: #fff;
  border-color: #3f3d56;
  color: #3f3d56;
}

/*animation and anti-aliasing*/
#blob,
button {
  transition: all ease 1s;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0);
}

#blob {
  transition-property: all, transform;
  transition-duration: 1s, 1s; /*2,1*/
}

.css-1oizbix {
  height: 4vh;
}

.actions {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  /* box-shadow: 5px 3px 17px 0 rgba(0, 0, 0, 0.13); */
  width: 35%;
  margin: 0% auto;
  margin-top: 6vh;
  padding: 1.2em;
  border: 2px solid black;
  border-radius: 4px;
}

.copyBtn {
  display: inline;
  background-color: #3f3d56;
  color: #fff;
  padding: 1em 1.5em;
  border: none;
  outline: none;
  border-radius: 4px;
  margin-bottom: 2vh;
  cursor: pointer;
  font-weight: bold;
}

.codeBlock {
  background-color: #424242;
  color: #fff;
  padding: 1em;
  border-radius: 4px;
  overflow: auto;
}

@media screen and (max-width: 768px) {
  #blob {
    width: 80%;
    padding: 0%;
    overflow-x: hidden;
  }
  .actions {
    width: 70%;
  }
  .modal {
    width: 80%;
  }
}

.twitterPicker {
  margin: 0 auto;
}

.navbar {
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;
  background-color: #fff;
  text-align: center;
  color: rgb(117, 113, 113);
  padding: 0.3em 4em;
}

.footer {
  position: relative;
  text-align: center;
  bottom: 0;
  background-color: #fff;
  width: 100%;
  color: rgb(117, 113, 113);
  border-top: 2px dashed rgb(179, 171, 171, 0.4);
}

.navbar a {
  color: rgb(117, 113, 113);
}

.details,
.author {
  background-color: #fff;
  color: black;
  padding: 0.5em 2em;
  margin-top: 2vh;
  text-align: center;
}

.author {
  margin-bottom: 3vh;
}

form {
  text-align: center;
  flex: 1;
  /* background-color: #3f3d56; */
  margin-bottom: 2em;
}

input[type="email"] {
  padding: 0.9em 1em;
  border-radius: 0%;
  border-right: none;
}

input[type="email"]::placeholder {
  font-family: "Exo", Arial, sans-serif;
}

button[type="submit"] {
  border: none;
  padding: 1em 2em;
  cursor: pointer;
  font-family: "Exo", Arial, sans-serif;
  /* width: 10%; */
  background: #6c63ff;
  color: #efefef;
  border-radius: 0%;
}

.successMsg {
  text-align: center;
}

.ureka-btn {
  width: fit-content;
  /* position: absolute; */
  /* bottom: 0; */
  /* margin-left: 38.5%; */
}
.ureka-btn-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  /* background-color: yellow; */
}